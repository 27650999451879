import { render, staticRenderFns } from "./integralrule.vue?vue&type=template&id=0b67e0d7&scoped=true"
import script from "./integralrule.vue?vue&type=script&lang=js"
export * from "./integralrule.vue?vue&type=script&lang=js"
import style0 from "./integralrule.vue?vue&type=style&index=0&id=0b67e0d7&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b67e0d7",
  null
  
)

component.options.__file = "integralrule.vue"
export default component.exports