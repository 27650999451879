<template>
  <div class="box">
    <div class="jbxx">
      <div class="jbtop">
        <div class="icon"></div>
        <div class="tit">基础配置</div>
      </div>
      <el-form ref="form" :model="form" label-width="200px">
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="每:">
              <el-input v-model="form.name"></el-input>

            </el-form-item>
            <div style="line-height: 30px;font-size: 14px;color: #998c85;width: 50px;">积分</div>
          </el-col>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="抵扣">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <div style="line-height: 30px;font-size: 14px;color: #998c85;">元</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="是否积分清零">
              <el-switch v-model="value1" inactive-text="按年付费">
              </el-switch>
            </el-form-item>

          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="每:">
              <el-input v-model="form.name"></el-input>

            </el-form-item>
            <div style="line-height: 30px;font-size: 14px;color: #998c85;">天</div>
          </el-col>

        </el-row>

      </el-form>
      <div class="jbtop" style="margin-top: 50px;">
        <div class="icon"></div>
        <div class="tit">积分设置</div>
      </div>
      <el-form ref="form" :model="form" label-width="200px">
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="会员日/特殊日期获得积分:">
              <el-input v-model="form.name"></el-input>

            </el-form-item>
            <div style="line-height: 30px;font-size: 14px;color: #998c85;">天</div>
          </el-col>

        </el-row>
      </el-form>
      <div class="butt">
        <!-- <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button> -->
        <el-button style="width: 140px;height: 50px;" type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  components: {   //界面组件引用
    quillEditor
  },
  data() {
    return {
      form: {
        name: '',

      },
      tableData: [
        { name: 'wdz' }
      ],
      showDialog: false

    };
  },
  mounted() {

  },
  methods: {
    handleSuccess(response, file, fileList) {
      console.log(response.data.url);
    },
    onSubmit() {
      // this.$router.push('/staff')
    },
    add() {
      this.showDialog = true
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
  padding-top: 20px;

  .jbxx {
    padding-right: 10px;
    .jbtop {
      height: 56px;
      background-color: #eef1f6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-left: 10px;
      margin-bottom: 40px;

      .icon {
        width: 6px;
        height: 19px;
        border-radius: 10px;
        background-color: #f77f1f;
        margin-right: 10px;
      }

      .tit {
        font-weight: 700;
      }


    }

    .edi {
      margin-left: 20px;
      height: 450px;
      display: flex;

      .editor {
        width: 817px;
        height: 372px;
        margin-top: 20px;
      }
    }
  }

  .butt {
    display: flex;
    margin-left: 50%;
  }

  .tab {
    padding: 20px;
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 230px;
    }
  }
}
</style>